<template>
  <div class="h-screen w-full page-container px-4 pt-16">
    <div class="bg-white shadow sm:rounded-lg max-w-xl m-auto mt-10">
      <component v-if="$route.params.pagename" :is="pageComponent" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from '@vue/runtime-core'

export default {
  computed: {
    pageComponent() {
      return defineAsyncComponent(
          () => import(`@/pages/${this.$route.params.pagename}`)
      )
    }
  }
}
</script>
<style>
.page-container {
  background-color: #f2f4f6;
}
</style>